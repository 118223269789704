import React, { useCallback } from "react";
import { useEffect } from "react";
import { LessonEvent as LessonEventModel } from "../models/lessonEvent";
import * as CalendarApi from "../network/googleCalendar_api";
import { makeNotification } from "../utils/toastNotification";
import { EventType } from "../components/partials/CalendarEvent";
import { TimeSlot } from "../pages/CalendarPage";
import { DiscordUser } from "../models/discordUser";

const useEvents = ({ loggedUser }: { loggedUser: DiscordUser | null }) => {
  const [eventsLoading, setEventsLoading] = React.useState(false);
  const [choosenWeekIndex, setChoosenWeekIndex] = React.useState(0);
  const [allLessonEvents, setAllLessonEvents] = React.useState<
    LessonEventModel[]
  >([]);
  const [busySlots, setBusySlots] = React.useState<Map<string, TimeSlot[]>>(
    new Map<string, TimeSlot[]>()
  );
  const startDay = new Date();
  const shiftAmount = (startDay.getDay() + 6) % 7;
  startDay.setDate(startDay.getDate() - shiftAmount + choosenWeekIndex * 7);

  const startHour = 8;
  const endHour = 22;
  const startMinutes = 0;
  const endMinutes = 0;

  const timeStep = 60; // in minutes
  const timeBufor = 15; // in minutes

  const createSlot = useCallback(
    (id: string, startDate: Date, endDate: Date, eventType: EventType) => {
      const parentDate = new Date(startDate);

      const startTimeZero = new Date(startDay);
      startTimeZero.setHours(0, 0, 0, 0);
      const eventTimeZero = new Date(startDate);
      eventTimeZero.setHours(0, 0, 0, 0);

      parentDate.setTime(
        Math.floor(
          (parentDate.getTime() - startMinutes * 60 * 1000) /
            (timeStep * 60 * 1000)
        ) *
          timeStep *
          60 *
          1000 +
          startMinutes * 60 * 1000
      );
      let slotDayIndex = Math.round(
        (eventTimeZero.getTime() - startTimeZero.getTime()) / (1000 * 3600 * 24)
      );
      const slotDayStart = new Date(startDate);
      slotDayStart.setHours(startHour);
      slotDayStart.setMinutes(startMinutes);

      const uniqueId =
        slotDayIndex +
        "|" +
        Math.floor(
          (startDate.getTime() - slotDayStart.getTime()) /
            (1000 * 60) /
            timeStep
        );
      const slotArray = busySlots.get(uniqueId) ?? [];
      slotArray?.push({
        id: id,
        startTime: startDate,
        endTime: endDate,
        offsetPercent: getTopPercent(parentDate, startDate) + "%",
        slotSpan: getTimeStepSpan(startDate, endDate),
        eventType: eventType,
      });
      busySlots.set(uniqueId, slotArray);
    },
    [busySlots, startDay]
  );

  function createBreak(slotEndDate: Date) {
    // const endDate = new Date(slotEndDate.getTime()+timeBufor*1000*60);
    // createSlot(slotEndDate, endDate, EventType.Break);
  }

  function getTopPercent(parentDate: Date, childDate: Date): number {
    const difference = Math.abs(childDate.getTime() - parentDate.getTime());
    return (
      (Math.round((difference / (60 * 1000) / timeStep) * 100) / 100) * 100
    );
  }
  function getTimeStepSpan(beginDate: Date, endDate: Date): number {
    const timeSpan =
      (endDate.getTime() - beginDate.getTime()) / (1000 * 60 * 60);
    return timeSpan + (timeSpan > 1 ? (timeSpan - 1) * 0.017 : 0);
  }
  useEffect(() => {
    async function loadEvents() {
      try {
        setEventsLoading(true);
        const currentWeekLessonEvents = await CalendarApi.fetchCurrentWeek(
          choosenWeekIndex
        );
        setAllLessonEvents(currentWeekLessonEvents);
        busySlots.clear();
        currentWeekLessonEvents.map((lessonEv) => {
          createSlot(
            lessonEv.id,
            new Date(lessonEv.startDate),
            new Date(lessonEv.endDate),
            lessonEv.discordId === (loggedUser?.discordId || " ")
              ? EventType.Reserved
              : EventType.Busy
          );
          createBreak(new Date(lessonEv.endDate));
        });
      } catch (error) {
        console.error(error);
        makeNotification("" + error, "Error");
      } finally {
        setEventsLoading(false);
      }
    }
    loadEvents();
  }, [choosenWeekIndex, busySlots]);

  return {
    eventsLoading,
    busySlots,
    setBusySlots,
    choosenWeekIndex,
    setChoosenWeekIndex,
  };
};

export default useEvents;
