import { Table } from "react-bootstrap";
import "../../styles/CalendarTable.module.css";
import CalendarCellTitle from "../partials/CalendarCellTitle";
import { TimeSlot } from "../../pages/CalendarPage";
import CalendarEvent from "../partials/CalendarEvent";
import { useRef } from "react";

interface CalendarTableProps {
  timeSlots: string[];
  busySlots: Map<string, TimeSlot[]>;
  timeStep: number;
  onEventDelete: (arg0: TimeSlot) => void;
  choosenWeekIndex: number;
}

const CalendarTable = ({
  timeSlots,
  busySlots,
  timeStep,
  onEventDelete,
  choosenWeekIndex,
}: CalendarTableProps) => {
  function generateWeekDates(startDate: Date, dayCount: number): Date[] {
    if (dayCount <= 0) return [];

    const result: Date[] = [];

    const shiftAmount = (startDate.getDay() + 6) % 7;
    startDate.setDate(startDate.getDate() - shiftAmount);
    startDate.setDate(startDate.getDate());
    startDate.setHours(0, 0, 0, 0);

    for (let i = 0; i < dayCount; i++) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() + i);
      result.push(newDate);
    }
    return result;
  }
  const currDate = new Date();
  currDate.setDate(currDate.getDate() + choosenWeekIndex * 7);
  const weekDates = useRef(generateWeekDates(currDate, 7));

  return (
    <>
      <div className="w-100" style={{ overflowX: "auto" }}>
        <Table className="mx-auto my-0">
          <thead>
            <tr>
              {weekDates.current?.map((date, index) => (
                <th
                  key={"thead" + index.toString()}
                  className={"text-center text-uppercase font-weight-bold"}
                >
                  {date.getDate() +
                    "." +
                    String(date.getMonth() + 1).padStart(2, "0") +
                    " (" +
                    date.toLocaleDateString("pl", { weekday: "short" }) +
                    ")"}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {timeSlots.map((slot, slotIndex) => (
              <tr key={slotIndex}>
                {weekDates.current.map((weekDate, weekIndex) => (
                  <td
                    key={"weekDates" + weekIndex}
                    className={"p-0 align-content-space flex-column"}
                    style={{ position: "relative" }}
                  >
                    <div className="w-100 h-100 d-flex flex-column justify-content-between">
                      <CalendarCellTitle content={slot.split("-")[0]} />
                      {
                        <span>
                          {busySlots.has(weekIndex + "|" + slotIndex) &&
                            busySlots
                              .get(weekIndex + "|" + slotIndex)!
                              .map((event, eventIndex) => (
                                <>
                                  <CalendarEvent
                                    topSpace={event!.offsetPercent}
                                    heightSize={
                                      event!.slotSpan * 100 * (60 / timeStep) +
                                      "%"
                                    }
                                    timeSlot={event!}
                                    onDelete={() => {
                                      onEventDelete(event!);
                                    }}
                                    key={eventIndex}
                                  />
                                </>
                              ))}
                        </span>
                      }
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const CalendarTableSkeleton = ({ timeSlots }: { timeSlots: string[] }) => {
  return (
    <CalendarTable
      timeSlots={timeSlots}
      busySlots={new Map()}
      timeStep={30}
      onEventDelete={() => {}}
      choosenWeekIndex={0}
    />
  );
};

export { CalendarTable, CalendarTableSkeleton };
