import { useEffect, useRef } from "react";

import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { DiscordUser } from "../models/discordUser";
import CalendarPageStyle from "../styles/CalendarPage.module.css";
import "../styles/CalendarPage.module.css";
import { LessonEvent as LessonEventModel } from "../models/lessonEvent";

import * as CalendarApi from "../network/googleCalendar_api";

import { EventType } from "../components/partials/CalendarEvent";
import DeleteDialog from "../components/dialogs/DeleteDialog";
import ReCAPTCHA from "react-google-recaptcha";

import { makeNotification } from "../utils/toastNotification";
import {
  CalendarTable,
  CalendarTableSkeleton,
} from "../components/calendar/CalendarTable";
import LoggedOutCard from "../components/partials/loggedOutCard";
import EventForm from "./components/EventForm";
import useEvents from "../hooks/useEvents";
import { generateWeekDates } from "../utils/week-dates";

interface CalendarPageProps {
  loggedUser: DiscordUser | null;
}

export interface TimeSlot {
  id: string;
  startTime: Date;
  endTime: Date;
  offsetPercent: string;
  slotSpan: number;
  eventType: EventType;
}

const CalendarPage = ({ loggedUser }: CalendarPageProps) => {
  const [allLessonEvents, setAllLessonEvents] = React.useState<
    LessonEventModel[]
  >([]);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [focusedEventId, setFocusedEventId] = React.useState<TimeSlot | null>(
    null
  );

  const startHour = 8;
  const endHour = 22;
  const startMinutes = 0;
  const endMinutes = 0;

  const timeStep = 60; // in minutes
  const timeBufor = 15; // in minutes

  const timeSlots = generateTimeSlots();

  const {
    eventsLoading,
    busySlots,
    setBusySlots,
    choosenWeekIndex,
    setChoosenWeekIndex,
  } = useEvents({ loggedUser });

  const startDay = new Date();
  const shiftAmount = (startDay.getDay() + 6) % 7;
  startDay.setDate(startDay.getDate() - shiftAmount);
  const endDay = new Date(startDay);
  startDay.setDate(startDay.getDate() + choosenWeekIndex * 7);
  endDay.setDate(endDay.getDate() + choosenWeekIndex * 7 + 6);

  function generateTimeSlots() {
    const timeSlots: string[] = [];

    let currentHour = startHour;
    let currentMinute = startMinutes;

    const endTotal = endHour * 100 + endMinutes;
    let currentTotal = startHour * 100 + startMinutes;
    while (currentTotal < endTotal) {
      let elem = `${currentHour}:${
        (currentMinute < 10 ? "0" : "") + currentMinute
      }-`;
      currentMinute += timeStep;
      currentHour += (currentMinute / 60) | 0; // hacky way to convert float to int
      currentMinute %= 60;
      elem += `${currentHour}:${
        (currentMinute < 10 ? "0" : "") + currentMinute
      }`;
      timeSlots.push(elem);

      currentTotal = currentHour * 100 + currentMinute;
    }
    return timeSlots;
  }

  function onEventDelete(timeslot: TimeSlot) {
    setFocusedEventId(timeslot);
    setShowDeleteDialog(true);
  }

  function changeWeek(direction: number) {
    setChoosenWeekIndex(choosenWeekIndex + direction);
  }

  async function onConfirmDelete() {
    try {
      if (!focusedEventId) {
        return;
      }
      await CalendarApi.deleteEvent(
        focusedEventId.id,
        focusedEventId?.startTime.toISOString()
      );
      setAllLessonEvents(
        allLessonEvents.filter((event) => event.id !== focusedEventId.id)
      );
      setBusySlots(new Map<string, TimeSlot[]>(busySlots));
    } catch (error) {
      makeNotification("" + error, "Error");
      console.error(error);
    }
  }

  function onEventSaved(newEvent: LessonEventModel) {
    setAllLessonEvents([...allLessonEvents, newEvent]);
    setBusySlots(new Map<string, TimeSlot[]>(busySlots));
  }

  const weekDates = generateWeekDates(startDay, 7);

  return (
    <>
      <div>
        <h1>
          Terminy (
          {startDay.getDate() +
            "." +
            String(startDay.getMonth() + 1).padStart(2, "0")}
          -
          {endDay.getDate() +
            "." +
            String(endDay.getMonth() + 1).padStart(2, "0")}
          ):
        </h1>
        {eventsLoading ? (
          <div className="position-relative">
            <CalendarTableSkeleton timeSlots={timeSlots} />
            <div className="position-absolute bg-trans-grey top-0 end-0 start-0 end-0 z-1 w-100 h-100">
              <Spinner className="position-absolute top-50 end-50 start-50 z-2" />
            </div>
          </div>
        ) : (
          <CalendarTable
            timeSlots={timeSlots}
            busySlots={busySlots}
            timeStep={timeStep}
            onEventDelete={onEventDelete}
            choosenWeekIndex={choosenWeekIndex}
          />
        )}
        <div className={CalendarPageStyle.navigation + " pt-1"}>
          {choosenWeekIndex !== 0 && (
            <Button
              onClick={() => changeWeek(-choosenWeekIndex)}
              className={"mx-1"}
            >
              {"Dzisiaj"}
            </Button>
          )}
          {choosenWeekIndex > -5 && (
            <Button onClick={() => changeWeek(-1)}>{"<"}</Button>
          )}
          <span className="p-2">
            {startDay.toLocaleDateString("pl")}-
            {endDay.toLocaleDateString("pl")}
          </span>
          {choosenWeekIndex < 5 && (
            <Button onClick={() => changeWeek(1)}>{">"}</Button>
          )}
        </div>
        <div className={CalendarPageStyle.bottomColumns}>
          <div className={CalendarPageStyle.leftColumn}>
            {!loggedUser && (
              <div className={CalendarPageStyle.formHide}>
                Zaloguj się, aby rezerwować terminy!
                <div>
                  <LoggedOutCard />
                </div>
              </div>
            )}
            <h1>Dodaj nowy termin:</h1>
            <EventForm
              onEventSaved={onEventSaved}
              busySlots={busySlots}
              setBusySlots={setBusySlots}
              allLessonEvents={allLessonEvents}
              setAllLessonEvents={setAllLessonEvents}
              weekDates={weekDates}
            />
          </div>
          <div className={"p-5 " + CalendarPageStyle.rightColumn}>
            <h5>Instrukcje:</h5>
            <p>
              Powyżej jest zamieszczony kalendarz na najbliższe 7 dni. Możesz na
              nim sprawdzić jakie terminy są wolne, jakie zajęte, a w jakich
              terminach są zaplanowane Twoje zajęcia. Możesz także odwołać
              zaplanowane zajęcia.
            </p>
            <h5>Legenda:</h5>
            <div>
              <p className="bg-light-danger p-1 text-dark rounded">
                Zajęty blok
              </p>
              <p className="bg-light-warning p-1 text-dark rounded">
                Twoje zajęcia
              </p>
            </div>
          </div>
        </div>
      </div>
      {showDeleteDialog && (
        <DeleteDialog
          content="Czy na pewno usunąć termin?"
          id={focusedEventId!.id}
          onConfirm={() => {
            onConfirmDelete();
            setShowDeleteDialog(false);
          }}
          onDismiss={() => setShowDeleteDialog(false)}
        />
      )}
    </>
  );
};

export default CalendarPage;
