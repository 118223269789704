import { DiscordUser } from "../models/discordUser";
import * as DiscordUserApi from "../network/discordUser_api";
import { useEffect, useState } from "react";

const useUser = () => {
  const [loggedInUser, setLoggedInUser] = useState<DiscordUser | null>(null);

  useEffect(() => {
    async function fetchLoggedInUser() {
      try {
        const user = await DiscordUserApi.fetchAuthenticatedUser();
        setLoggedInUser(user);
      } catch (error) {
        // User is not authenticated
        console.log(error);
      }
    }
    fetchLoggedInUser();
  }, []);

  const logout = () => {
    setLoggedInUser(null);
  };

  return [loggedInUser, logout] as const;
};

export default useUser;
