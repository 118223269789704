import Header from "./components/Header";
import ExercisesPage from "./pages/ExercisesPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForbiddenPage from "./pages/ForbiddenPage";
import Other from "./pages/Other";
import Calendar from "./pages/CalendarPage";
import HomepagePage from "./pages/HomepagePage";
import NotFoundPage from "./pages/NotFoundPage";
import ExercisePage from "./pages/ExercisePage";
import AdminPage from "./pages/AdminPage";
import Footer from "./components/Footer";
import SearchPage from "./pages/SearchPage";
import AboutMePage from "./pages/AboutMePage";
import { ToastContainer } from "react-toastify";
import useUser from "./hooks/useUser";

function App() {
  const [currentUser, logout] = useUser();

  return (
    <>
      <BrowserRouter>
        <Header loggedUser={currentUser} onLogoutSuccessful={logout} />
        <div className="headerMargin"></div>
        <div className="mainContainer">
          <div className="content">
            <Routes>
              <Route path="/" element={<HomepagePage />} />
              <Route
                path="/exercises/:id"
                element={<ExercisePage loggedUser={currentUser} />}
              />
              <Route path="/exercises" element={<ExercisesPage />} />
              <Route path="/forbidden" element={<ForbiddenPage />} />
              <Route path="/auth/failure" element={<HomepagePage />} />
              <Route path="/auth/success" element={<HomepagePage />} />
              <Route path="/other" element={<Other />} />
              <Route
                path="/calendar"
                element={<Calendar loggedUser={currentUser} />}
              />
              <Route path="/about" element={<AboutMePage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
            <ToastContainer />
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
