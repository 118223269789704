export function generateWeekDates(startDate: Date, dayCount: number): Date[] {
  if (dayCount <= 0) return [startDate];

  let result: Date[] = [];
  for (let i = 0; i < dayCount; i++) {
    const newDate = new Date(startDate);
    newDate.setDate(newDate.getDate() + i);
    result.push(newDate);
  }
  return result;
}
