import { Form, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import FormError from "../../components/partials/FormError";
import { stringToTime } from "../../utils/formatTime";
import * as ReCaptchaApi from "../../network/reCaptcha_api";
import * as CalendarApi from "../../network/googleCalendar_api";
import { useForm } from "react-hook-form";
import useUser from "../../hooks/useUser";
import { makeNotification } from "../../utils/toastNotification";
import React from "react";
import { EventInput } from "../../network/googleCalendar_api";
import { TimeSlot } from "../CalendarPage";
import { LessonEvent as LessonEventModel } from "../../models/lessonEvent";
import CalendarPageStyle from "../../styles/CalendarPage.module.css";

interface CalendarEventInputForm {
  eventDate: string;
  eventBeginTime: string;
  eventDuration: number;
}

const EventForm = ({
  allLessonEvents,
  setAllLessonEvents,
  busySlots,
  setBusySlots,
  onEventSaved,
  weekDates,
}: {
  allLessonEvents: LessonEventModel[];
  setAllLessonEvents: (v: LessonEventModel[]) => void;
  busySlots: any;
  setBusySlots: any;
  onEventSaved: (newEvent: LessonEventModel) => void;
  weekDates: Date[];
}) => {
  const {
    setError,
    clearErrors,
    getValues,
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CalendarEventInputForm>({
    defaultValues: {
      eventDate: undefined,
      eventBeginTime: undefined,
      eventDuration: undefined,
    },
  });

  const startHour = 10;
  const endHour = 22;
  const startMinutes = 0;
  const endMinutes = 0;
  const [currentUser, logout] = useUser();
  const recaptcha = React.useRef<ReCAPTCHA>(null);
  const [focusedEventId, setFocusedEventId] = React.useState<TimeSlot | null>(
    null
  );
  async function validateNewDate(
    newEventBeginDate: string,
    newEventBeginTime: string,
    newEventDuration: number
  ): Promise<boolean> {
    if (!newEventBeginDate || !newEventBeginTime || !newEventDuration) {
      return false;
    }

    const beginDate = new Date(newEventBeginDate);
    const splitTime = newEventBeginTime.split(":"),
      hour = +splitTime[0],
      minute = +splitTime[1];
    beginDate.setHours(hour);
    beginDate.setMinutes(minute);
    const endDate = new Date(beginDate);
    endDate.setMinutes(beginDate.getMinutes() + newEventDuration * 60);
    const response = await CalendarApi.checkTimeSlot({
      beginDate: beginDate.toISOString(),
      endDate: endDate.toISOString(),
    });
    return response.state;
  }
  async function onSubmit(input: CalendarEventInputForm) {
    if (!currentUser) {
      //setError("root.auth", {message: "Użytkownik nie jest zalogowany."});
      console.log("Użytkownik nie jest zalogowany.");
      makeNotification("Użytkownik nie jest zalogowany.", "Error");
      return;
    }

    const captchaValue = recaptcha.current?.getValue();
    const response = await ReCaptchaApi.verifyReCAPTCHA(captchaValue ?? "");
    if (!captchaValue || !response.success) {
      //setError("root.recaptcha", {message: "Weryfikacja reCAPTCHA nie powiodła się."});
      console.log("Weryfikacja reCAPTCHA nie powiodła się.");
      makeNotification("Weryfikacja reCAPTCHA nie powiodła się.", "Error");
      return;
    }

    const splitTime = input.eventBeginTime.split(":"),
      totalEndMins =
        60 * +splitTime[0] + +splitTime[1] + input.eventDuration * 60;
    const endMins = endHour * 60 + endMinutes;

    if (totalEndMins > endMins) {
      //setError("root.busy", { message: "Zajęcia kończą się za późno."});
      console.log("Zajęcia kończą się za późno.");
      makeNotification("Zajęcia kończą się za późno.", "Error");
      return;
    }

    const currentDate = new Date();
    const constructedDate = new Date(input.eventDate);
    constructedDate.setHours(+splitTime[0], +splitTime[1], 0, 0);
    if (constructedDate.getTime() < currentDate.getTime()) {
      console.log("Nie można rezerwować lekcji w przeszłości.");
      makeNotification("Nie można rezerwować lekcji w przeszłości.", "Error");
      return;
    }

    recaptcha.current?.reset();

    const timeSlotValidation = await validateNewDate(
      input.eventDate,
      input.eventBeginTime,
      input.eventDuration
    );
    if (!timeSlotValidation) {
      //setError("root.busy", { message: "Termin nie jest dostępny."});
      console.log("Termin nie jest dostępny.");
      makeNotification("Termin nie jest dostępny.", "Error");
      return;
    }

    try {
      const arr = input.eventBeginTime.split(":");
      const start = new Date(input.eventDate);
      start.setHours(+arr[0]);
      start.setMinutes(+arr[1]);
      const end = new Date(start);
      end.setMinutes(+arr[1] + input.eventDuration * 60);

      const newEvent: EventInput = {
        beginDate: start.toISOString(),
        endDate: end.toISOString(),
        discordId: currentUser.discordId,
      };
      const response = await CalendarApi.createEvent(newEvent);
      onEventSaved(response);
    } catch (error) {
      console.log(error);
      makeNotification("" + error, "Error");
    }
  }

  async function onConfirmDelete() {
    try {
      if (!focusedEventId) {
        return;
      }
      await CalendarApi.deleteEvent(
        focusedEventId.id,
        focusedEventId?.startTime.toISOString()
      );
      setAllLessonEvents(
        allLessonEvents.filter((event) => event.id !== focusedEventId.id)
      );
      setBusySlots(new Map<string, TimeSlot[]>(busySlots));
    } catch (error) {
      makeNotification("" + error, "Error");
      console.error(error);
    }
  }

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={CalendarPageStyle.newEventForm}
      id="newEventForm"
    >
      <Form.Group className="mb-3">
        <Form.Label>Data</Form.Label>
        <Form.Select
          isInvalid={!!errors.eventDate}
          disabled={isSubmitting || !currentUser}
          {...register("eventDate", {
            required: "Wymagane",
          })}
        >
          {weekDates.map((date, weekIndex) => (
            <option
              key={"form" + weekIndex}
              value={date.toISOString()}
              disabled={date < currentDate}
            >
              {date.getDate() +
                "." +
                String(date.getMonth() + 1).padStart(2, "0") +
                " (" +
                date.toLocaleDateString("pl", { weekday: "long" }) +
                ")"}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.eventDate?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Godzina rozpoczęcia</Form.Label>
        <Form.Control
          isInvalid={!!errors.eventBeginTime}
          disabled={isSubmitting || !currentUser}
          type="time"
          step="60"
          min={startHour + ":" + startMinutes.toString().padStart(2, "0")}
          max={endHour + ":" + endMinutes.toString().padStart(2, "0")}
          placeholder="16:30"
          {...register("eventBeginTime", {
            required: "Wymagane",
            validate: {
              earlierThanPossible: (v) =>
                stringToTime(v) >=
                  stringToTime(startHour + ":" + startMinutes) ||
                `Nie można rezerwować lekcji przed ${startHour}:${startMinutes
                  .toString()
                  .padStart(2, "0")}`,

              laterThanPossible: (v) =>
                stringToTime(v) <= stringToTime(endHour + ":" + endMinutes) ||
                `Nie można rezerwować lekcji po ${endHour}:${endMinutes
                  .toString()
                  .padStart(2, "0")}`,
            },
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.eventBeginTime?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Czas trwania</Form.Label>
        <Form.Select
          isInvalid={!!errors.eventDuration}
          disabled={isSubmitting || !currentUser}
          {...register("eventDuration", {
            required: "Wymagane",
          })}
        >
          <option value="1">1 godzina</option>
          <option value="1.5">1 godzina 30 minut</option>
          <option value="2">2 godziny</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.eventDuration?.message}
        </Form.Control.Feedback>
      </Form.Group>

      {errors.root && (
        <>
          <FormError message={errors.root.busy?.message} />
          <FormError message={errors.root.recaptcha?.message} />
          <FormError message={errors.root.auth?.message} />
        </>
      )}
      <Form.Group className="mb-3">
        <ReCAPTCHA
          sitekey="6LeOcHspAAAAAKR66sValZZdFEv91Jz1P5ZeRanD"
          ref={recaptcha}
          theme="dark"
          onError={() => {}}
        />
      </Form.Group>
      <Button
        type="submit"
        disabled={isSubmitting || !currentUser}
        form="newEventForm"
      >
        Dodaj termin
      </Button>
    </Form>
  );
};

export default EventForm;
